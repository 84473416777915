import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BASE_URL from '../../../BASE_URL';
import Swal from 'sweetalert2';
import { RiDeleteBin6Line } from 'react-icons/ri';

const VehicleModel = () => {
    const [vehicleModels, setVehicleModels] = useState([]);
    const [checked, setChecked] = useState(false);
    const [singleChecked, setSingleChecked] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;


      
    const removedFun = (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`${BASE_URL}admin/vehicleModel/deleteById/${id}`)
              .then(async(result) => {
                console.log(result.data.status);
    
                if (result.data.status === "success") {
                 
                
                    
                
    
                  Swal.fire("Deleted!", "Your file has been deleted.", "success");

             
                const response = await axios.get(`${BASE_URL}admin/vehicleModel/getAll`);
                setVehicleModels(response.data.data);
           
                }
              });
          }
        });
      };
 


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}admin/vehicleModel/getAll`);
                setVehicleModels(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className={"login-activity"}>
            <div className="">
                <div className="container-fluid p-5">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="/react/demo/table-bootstrap-basic">Vehicle Model</a>
                            </li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title h5">Vehicle Model</div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="width50">
                                                        <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="checkAll"
                                                                onClick={() => setChecked(!checked)}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="checkAll"
                                                            />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <strong>ID_</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Brand Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Model Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Created at</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Action</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vehicleModels.map((model, index) => (
                                                    <tr key={index}>
                                                         <td>
                              <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                {checked?<input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheckBox${index}`}
                                  checked={true}
                                  onClick={() => setSingleChecked(index)}
                                />:<input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customCheckBox${index}`}
                                onClick={() => setSingleChecked(index)}
                              />}
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customCheckBox${index}`}
                                />
                              </div>
                            </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{index + 1}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{model.brand}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{model.model}</span>
                                                            </div>
                                                        </td>
                                                        <td>{model.createdAt}</td>

                                                        <td>
                                <RiDeleteBin6Line onClick={()=>removedFun(model?.id)} size={20} color="red" />
                              </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/addModel'} className={"add-btn"} onClick={() => {}}>+</Link>
            </div>
        </div>
    );
};

export default VehicleModel;