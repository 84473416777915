import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UrlArgsBundle from '../../../core/url_args_bundle';
import '../../../css/main.6120d571.css';
import './reviews.css';
import user from '../../../images/male-02.jpg';
import axios from 'axios';
import BASE_URL from '../../../BASE_URL';
import Rating from '../../../components/rating/Rating';

function Reviews(props) {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = () => {
    axios
      .get(`${BASE_URL}rider/rider/allReviews`)
      .then((res) => {
        console.log(res.data);
        setReviews(res.data.reviews);
      })
      .catch((error) => {
        console.log(error);
      });
  };
//  console.log(reviews)

  return (
    <div className={'login-activity'}>
      <div className="">
        <div className="container-fluid p-5">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="/react/demo/table-bootstrap-basic">Reviews</a>
              </li>
            </ol>
          </div>
          <div className="row">
            { reviews?.map((review) => {
              return (
                <div key={review.id} className="col-md-5 mx-auto rounded bg-white mt-5">
                <div className="reviews">
                  <div className="reviews-inner p-3">
                    <div className="user_images">
                       <div style={{ backgroundColor:'#dcdcdc', border:'2px solid #dc1f26 ', width:'60px' ,height:'60px', borderRadius:'100%' ,textAlign:'center', padding:'2px'}}>
             <svg width='50px '  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 8 1 c -1.65625 0 -3 1.34375 -3 3 s 1.34375 3 3 3 s 3 -1.34375 3 -3 s -1.34375 -3 -3 -3 z m -1.5 7 c -2.492188 0 -4.5 2.007812 -4.5 4.5 v 0.5 c 0 1.109375 0.890625 2 2 2 h 8 c 1.109375 0 2 -0.890625 2 -2 v -0.5 c 0 -2.492188 -2.007812 -4.5 -4.5 -4.5 z m 0 0" fill="#2e3436"></path> </g></svg>

             </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                    <h5>
                      {review?.user?.firstName}
                    </h5>
                    {/* <h5>
                      {`${review?.ars?.firstName}  ${review?.ars?.lastName}`}
                    </h5> */}
                    </div>
                    
                    {/* <p className="desc">{review.review}</p> */}
                    <hr />
                    <div className="row">
                      <div className="col-md-5">
                        <div className="rating">
                          <Rating text={review.rating} />
                        </div>
                      </div>
                      <div className="col-md-7 text-right">
                        <span>{review?.user?.createdAt }</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default  Reviews;