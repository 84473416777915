import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import "../../../css/main.6120d571.css";
import BASE_URL from "../../../BASE_URL";
import Swal from "sweetalert2";
import axios from "axios";
import { RiDeleteBin6Line } from "react-icons/ri";

function Trips() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [trips, setTrips] = useState([]);
  const [rideCity, setRideCity] = useState([]);
  const [checked, setChecked] = useState(false);
  const [singleChecked, setSingleChecked] = useState(false);


  const [activeNav,setActiveNav] = useState('betting')

  useEffect(() => {
    const axios = require("axios");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}admin/rideQuick/getCompleted`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.data);
        setTrips(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    let config2 = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}admin/rideCity/getCompleted`,
      headers: {},
    };

    axios
      .request(config2)
      .then((response) => {
        console.log(response.data.data);
        setRideCity(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  const removedFun = (id)=>{



    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc1f26',
      cancelButtonColor: '#dc1f26',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
         
           axios.delete(`${BASE_URL}admin/rideQuick/deleteById/${id}`).then((result) => {
               
               

            console.log(result);

            if(result.data.status==='success'){

              const axios = require("axios");

              let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${BASE_URL}admin/rideQuick/getCompleted`,
                headers: {},
              };
          
              axios
                .request(config)
                .then((response) => {
                  console.log(response.data.data);
                  setTrips(response.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
              
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )

        
            }
               
           })
  
  
      }
    })

   }


  const removedFunCity = (id)=>{



    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
         
           axios.delete(`${BASE_URL}admin/rideCity/deleteById/${id}`).then((result) => {
               
               

            console.log(result);

            if(result.data.status==='success'){

              const axios = require("axios");

              let config2 = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${BASE_URL}admin/rideCity/getCompleted`,
                headers: {},
              };
          
              axios
                .request(config2)
                .then((response) => {
                  console.log(response.data.data);
                  setRideCity(response.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
              
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )

        
            }
               
           })
  
  
      }
    })

   }

  return (
    <div className={"login-activity"}>
      <div className="container-fluid p-5">
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="/react/demo/table-bootstrap-basic">Trips</a>
            </li>
          </ol>
        </div>

       
        <div style={{ display: 'flex', gap: '8px'}} className="pb-4">
              <Link
                 onClick={()=>{
                  setActiveNav('betting')
                 }}
                className="btn btn-danger"
                style={{backgroundColor:activeNav==='betting'?'#dc1f26':'#fff', borderColor: '#dc1f26',color:activeNav==='betting'?'#fff':'#dc1f26'}}
              >
                Quick Ride Trips
              </Link>
              <Link
                onClick={()=>{
                  setActiveNav('city')
                 }}
                className="btn btn-danger"
                style={{backgroundColor:activeNav==='city'?'#dc1f26':'#fff', borderColor: '#dc1f26',color:activeNav==='city'?'#fff':'#dc1f26'}}
              >
                City to City Trips
              </Link>
            </div>



            {activeNav==='betting'?<div className="row">
          <div className="col-lg-12 all_col">
            <div className="card">
              <div className="card-header">
                <div className="card-title h5">Quick Ride Trips</div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="width50">
                          <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="checkAll"
                              onClick={() => {
                                setChecked(!checked);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkAll"
                            />
                          </div>
                        </th>
                        <th>
                          <strong>DRIVER</strong>
                        </th>
                        <th>
                          <strong>USER</strong>
                        </th>
                        <th>
                          <strong>DRIVER PHONE</strong>
                        </th>
                        <th>
                          <strong>RIDE DATE</strong>
                        </th>
                        <th>
                          <strong>ROUTE</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {trips.map((trip, index) => (
                        <tr key={index}>
                          <td>
                              <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                {checked?<input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheckBox${index}`}
                                  checked={true}
                                  onClick={() => setSingleChecked(index)}
                                />:<input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customCheckBox${index}`}
                                onClick={() => setSingleChecked(index)}
                              />}
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customCheckBox${index}`}
                                />
                              </div>
                            </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <span className="w-space-no">
                                {trip?.riderData?.firstName +
                                  " " +
                                  trip?.riderData?.lastName}
                              </span>
                            </div>
                          </td>
                          <td>
                            {trip?.userData?.firstName +
                              " " +
                              trip?.userData?.lastName}
                          </td>
                          <td>{trip?.riderData?.phone}</td>
                          <td>{trip?.rideData?.createdAt}</td>
                          <td>
                            {trip?.rideData?.pickupLocation +
                              " " +
                              trip?.rideData?.dropLocation}
                          </td>
                          <td>
                                <RiDeleteBin6Line onClick={()=>removedFun(trip?.rideData?.id)} size={20} color="red" />
                              </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>:
        <div className="row">
        <div className="col-lg-12 all_col">
          <div className="card">
            <div className="card-header">
              <div className="card-title h5">City To City Trips</div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="width50">
                        <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkAll"
                            onClick={() => {
                              setChecked(!checked);
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkAll"
                          />
                        </div>
                      </th>
                      <th>
                        <strong>DRIVER</strong>
                      </th>
                      <th>
                        <strong>USER</strong>
                      </th>
                      <th>
                        <strong>DRIVER PHONE</strong>
                      </th>
                      <th>
                        <strong>RIDE DATE</strong>
                      </th>
                      <th>
                        <strong>ROUTE</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rideCity.map((trip, index) => (
                      <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                              {checked?<input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customCheckBox${index}`}
                                checked={true}
                                onClick={() => setSingleChecked(index)}
                              />:<input
                              type="checkbox"
                              className="custom-control-input"
                              id={`customCheckBox${index}`}
                              onClick={() => setSingleChecked(index)}
                            />}
                              <label
                                className="custom-control-label"
                                htmlFor={`customCheckBox${index}`}
                              />
                            </div>
                          </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">
                              {trip?.riderData?.firstName +
                                " " +
                                trip?.riderData?.lastName}
                            </span>
                          </div>
                        </td>
                        <td>
                          {trip?.userData?.firstName +
                            " " +
                            trip?.userData?.lastName}
                        </td>
                        <td>{trip?.riderData?.phone}</td>
                        <td>{trip?.rideData?.createdAt}</td>
                        <td>
                          {trip?.rideData?.pickupLocation +
                            " " +
                            trip?.rideData?.dropLocation}
                        </td>
                        <td>
                              <RiDeleteBin6Line onClick={()=>removedFunCity(trip?.rideData?.id)} size={20} color="red" />
                            </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>}
        
      </div>
    </div>
  );
}

export default  Trips