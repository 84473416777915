import React, { useState } from "react";
import { useNavigate, withRouter } from "react-router-dom";
import UrlArgsBundle from "../../../core/url_args_bundle";
import "../../../css/main.6120d571.css";

import axios from "axios";
import io from "socket.io-client";
import "./notifications.css";
import BASE_URL from "../../../BASE_URL";
import { toast } from "react-toastify";

function Notifications() {

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);
  const [state, setState] = useState({
    title: "",
    body: "",
  });

  const sendDataToRegisterApi = () => {


           console.log(state.title,state.body);
           if(!state.title){
             toast.success("Please Enter your Title!");

           }else if (!state.body){
            toast.success("Please Enter Your Message!");

           }else {
            const axios = require("axios");
            const qs = require("qs");
            
            let data = qs.stringify({
                'title': state.title,
                'body': state.body
            });
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BASE_URL}admin/notification/add`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
    
            axios.request(config)
                .then((response) => {
                    // toast.success("Vehicle Model Added Successfully")
                   console.log(response);
                    setLoading(false);
                    if(response.data.status==='success'){
    
                        toast.success("notification  Send Successfully!");
                
                
                       }
                       
                    
                })
                .catch((error) => {
                    console.log(error);
                });

           }
       
    
}

  return (
    <div className={"login-activity"}>
      <div className="container-fluid p-5">
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="/react/demo/table-bootstrap-basic">Notifications</a>
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-lg-12 all_col">
            <div className="card pb-4">
              <div className="card-header">
                <div className="card-title h5">Notifications</div>
              </div>
              <div className="notifications p-4">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setState({ ...state, title: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Message
                  </label>
                  <div className="form-floating">
                    <textarea
                      onChange={(e) =>
                        setState({ ...state, body: e.target.value })
                      }
                      className="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <button
                  onClick={sendDataToRegisterApi}
                  className="btn px-5 mt-3"
                  style={{ backgroundColor: "#dc1f26", color: "#fff" }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default Notifications;
