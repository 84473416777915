import React from 'react'

const Footer = () => {
  return (
    <>
 <div className="footer">
              <div className="copyright">
                <p>
                  Copyright © Designed &amp; Developed by{" "}
                  <a href="https://wolftechs.pk/" target="_blank">
                    WOlf Tech Solutions PVT LTD
                  </a>{" "}
                  2022
                </p>
              </div>
            </div>
    </>
  )
}

export default Footer