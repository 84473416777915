import React, { useState, useEffect } from 'react';
import '../../css/main.6120d571.css';
import '../../App.css';
import { Link } from 'react-router-dom';

function Sidebar({width,display}) {
    const [activeItem, setActiveItem] = useState('home');

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.clear();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        localStorage.setItem('activeNav', itemName);
    };

    return (
        <div className={"Navbar"} id='Sidebar' style={{width:width,display: display}}>
        
<div className="dlabnav" >

<div className=' text-center' style={{height:'100px',alignItems:'center',justifyContent:'center',display:'flex'}}>
            <Link>

            <img  src={require('../../images/ashhkaro.png')} width="140px" />
            </Link>
        </div>
    
<div className="scrollbar-container dlabnav-scroll ps">
    <div className="mm-wrapper">
        <ul className="metismenu">
            <li className="mm-active"

            >
                <Link
                    to="/"


                    onClick={() => {






                        setActiveItem('/')

                    }}




                    className={activeItem === '/' ? 'activeNavbar' : 'ai-icon'} href="">
                    <i className={activeItem === '/' ? 'activeNavbar fa-solid fa-house' : 'fa-solid fa-house'} />
                    <span className={activeItem === '/' ? 'activeNavbar' : 'nav-text'}>Dashboard</span>
                </Link>
            </li>
            <li className=""
            >
                <Link

                    to='/clients'

                    onClick={() => {

                    setActiveItem('clients')
                    }}
                    className={activeItem === 'clients' ? 'activeNavbar' : 'ai-icon'} href="">

                    <i className={activeItem=== 'clients' ? 'activeNavbar fa-solid fa-users' : 'fa-solid fa-users'} />

                    <span className={activeItem === 'clients' ? 'activeNavbar' : 'nav-text'}>Clients</span>
                </Link>
            </li>
            <li className="" onClick={() => {


            setActiveItem('support')
            }}

            >
                <Link to="/supportSystem" className={activeItem === 'support' ? 'activeNavbar' : 'ai-icon'} href="">



                    <i className={activeItem === 'support' ? 'activeNavbar fa-solid fa-headset' : 'fa-solid fa-headset'} />
                    <span className={activeItem === 'support' ? 'activeNavbar' : 'nav-text'}> Support System</span>
                </Link>
            </li>
            <li className="" onClick={() => {


           setActiveItem('captains')
            }}>
                <Link to={'/captains'} className={activeItem === 'captains' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem === 'captains' ? 'activeNavbar fa-solid fa-ship' : 'fa-solid fa-ship'} />
                    <span className={activeItem === 'captains' ? 'activeNavbar' : 'nav-text'}> Captains</span>
                </Link>
            </li>

            <li className="" onClick={() => {

                setActiveItem('brand')
            }}>
                <Link to='/vehicleBrand' className={activeItem === 'brand' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem === 'brand' ? 'activeNavbar fa-brands fa-staylinked' : 'fa-brands fa-staylinked'} />
                    <span className={activeItem === 'brand' ? 'activeNavbar' : 'nav-text'}> Vehicle Brand</span>
                </Link>
            </li>
            <li className="" onClick={() => {


            setActiveItem('model')
            }}>
                <Link to={'/vehicleModel'} className={activeItem === 'model' ? 'activeNavbar' : 'ai-icon'} href="">

                    <i className={activeItem=== 'model' ? 'activeNavbar fa-solid fa-car' : 'fa-solid fa-car'} />
                    <span
                        className={activeItem === 'model' ? 'activeNavbar' : 'nav-text'}
                    > Vehicle Model</span>
                </Link>
            </li>
            <li className="" onClick={() => {

            setActiveItem('colors')
            }}>
                <Link to={'/vehicleColor'} className={activeItem=== 'colors' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem === 'colors' ? 'activeNavbar fa-solid fa-palette' : 'fa-solid fa-palette'} />
                    <span
                        className={activeItem=== 'colors' ? 'activeNavbar' : 'nav-text'}
                    > Vehicle Color</span>
                </Link>
            </li>
            <li className="" onClick={() => {

            setActiveItem('ride_categories')
            }}>
                <Link to="/rideCategories" className={activeItem === 'ride_categories' ? 'activeNavbar' : 'ai-icon'} href="">

                    <i className={activeItem === 'ride_categories' ? 'activeNavbar fa-solid fa-grip' : 'fa-solid fa-grip'} />
                    <span className={activeItem === 'ride_categories' ? 'activeNavbar' : 'nav-text'}> Ride Categories</span>
                </Link>
            </li>
            <li className=""
                onClick={() => {

                setActiveItem('trips')
                }}
            >
                <Link to='/trips' className={activeItem === 'trips' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem=== 'trips' ? 'activeNavbar fa-solid fa-bus' : 'fa-solid fa-bus'} />
                    <span className={activeItem=== 'trips' ? 'activeNavbar' : 'nav-text'}> Trips</span>
                </Link>
            </li>
            <li className=""
                onClick={() => {

                  setActiveItem('cancelled_trips')
                }}
            >
                <Link to='/cancelledTrips' className={activeItem === 'cancelled_trips' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem === 'cancelled_trips' ? 'activeNavbar fa-solid fa-bus' : 'fa-solid fa-bus'} />
                    <span

                        className={activeItem === 'cancelled_trips' ? 'activeNavbar' : 'nav-text'}
                    > Cancelled Trips</span>
                </Link>
            </li>

            <li
                onClick={() => {


                    setActiveItem('reviews')
                }}

                className="">
                <Link to={'/reviews'} className={activeItem === 'reviews' ? 'activeNavbar' : 'ai-icon'} href="">



                    <i className={activeItem=== 'reviews' ? 'activeNavbar fa-solid fa-comment-dots' : 'fa-solid fa-comment-dots'} />
                    <span className={activeItem === 'reviews' ? 'activeNavbar' : 'nav-text'}> Reviews</span>
                </Link>
            </li>
            <li
                onClick={() => {


                    setActiveItem('faq')
                }}

                className="">
                <Link to={'/faq'} className={activeItem === 'faq' ? 'activeNavbar' : 'ai-icon'} href="">


                    <i className={activeItem === 'faq' ? 'activeNavbar fa-solid fa-comment-dots' : 'fa-solid fa-comment-dots'} />
                    <span
                        className={activeItem=== 'faq' ? 'activeNavbar' : 'nav-text'}
                    > Faq</span>
                </Link>
            </li>

            <li

                onClick={() => {

                    setActiveItem('notifications')
                }}
                className="">
                <Link className={activeItem=== 'notifications' ? 'activeNavbar' : 'ai-icon'} to="/notifications">



                    <i className={activeItem === 'notifications' ? 'activeNavbar fa-solid fa-bell' : 'fa-solid fa-bell'} />
                    <span
                        className={activeItem === 'notifications' ? 'activeNavbar' : 'nav-text'}
                    > Notifications</span>
                </Link>
            </li>

        </ul>
    </div>
    <div className="copyright">
        <p>
            <strong>Ashkaro </strong> © 2022 All Rights Reserved
        </p>
        <p>
            Made  by Wolf software solution PVT LTD
        </p>
    </div>
    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
        <div
            className="ps__thumb-x"
            tabIndex={0}
            style={{ left: 0, width: 0 }}
        />
    </div>
    <div className="ps__rail-y" style={{ top: 0, right: 0, height: 2975 }}>
        <div
            className="ps__thumb-y"
            tabIndex={0}
            style={{ top: 0, height: 0 }}
        />
    </div>
</div>
</div>




        </div>
    );
}

export default Sidebar;



